export class Icon {
  id: number;
  name: string;
  alt: string;
  src: string
  constructor(value: Icon) {
    this.id = value.id ?? 0;
    this.name = value.name ?? "";
    this.alt = value.alt ?? "";
    this.src = value.src ?? "";

  }
}

export const icons: Icon[] = [
  {
    id: 0,
    name: "",
    alt: "",
    src: "",
  },
  {
    id: 1,
    name: "person",
    alt: "person",
    src: "/assets/icons/person.svg",
  },
  {
    id: 2,
    name: "logout",
    alt: "logout",
    src: "/assets/icons/logout.svg",
  },
  {
    id: 3,
    name: "check",
    alt: "check",
    src: "/assets/icons/check.svg",
  },
  {
    id: 4,
    name: "arrow",
    alt: "arrow",
    src: "/assets/icons/arrow.svg",
  },
  {
    id: 5,
    name: "plus",
    alt: "plus",
    src: "/assets/icons/plus.svg",
  },
  {
    id: 6,
    name: "context-menu",
    alt: "context-menu",
    src: "/assets/icons/context-menu.svg",
  },
  {
    id: 7,
    name: "pencil",
    alt: "pencil",
    src: "/assets/icons/pencil.svg",
  },
  {
    id: 8,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/arrow-selector.svg",
  },
  {
    id: 9,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/cross-close.svg",
  },
  {
    id: 10,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/select-item.svg",
  },
  {
    id: 11,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/filter.svg",
  },
  {
    id: 12,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/none-sort.svg",
  },
  {
    id: 13,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/asc.svg",
  },
  {
    id: 14,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/desc.svg",
  },
  {
    id: 15,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/none-sort-mini.svg",
  },
  {
    id: 16,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/asc-mini.svg",
  },
  {
    id: 17,
    name: "arrow-selector",
    alt: "arrow-selector",
    src: "/assets/icons/desc-mini.svg",
  },
  {
    id: 18,
    name: "trash",
    alt: "trash",
    src: "/assets/icons/trash.svg",
  },
  {
    id: 19,
    name: "quotation-left",
    alt: "quotation-left",
    src: "/assets/icons/quotation-left.svg",
  },
  {
    id: 20,
    name: "quotation-right",
    alt: "quotation-right",
    src: "/assets/icons/quotation-right.svg",
  },
  {
    id: 21,
    name: "calendar",
    alt: "calendar",
    src: "/assets/icons/calendar.svg",
  },
  {
    id: 22,
    name: "calendar-day",
    alt: "calendar-day",
    src: "/assets/icons/calendar-day.svg",
  },
  {
    id: 23,
    name: "filter-big",
    alt: "filter-big",
    src: "/assets/icons/filter-big.svg",
  },
  {
    id: 24,
    name: "calendar-blue",
    alt: "calendar-blue",
    src: "/assets/icons/calendar-blue.svg",
  },
  {
    id: 25,
    name: "calendar-day-blue",
    alt: "calendar-day-blue",
    src: "/assets/icons/calendar-day-blue.svg",
  },
  {
    id: 26,
    name: "success",
    alt: "success",
    src: "/assets/icons/success.svg",
  },
  {
    id: 27,
    name: "sand-clock",
    alt: "sand-clock",
    src: "/assets/icons/sand-clock.svg",
  },
  {
    id: 28,
    name: "finish-flag",
    alt: "finish-flag",
    src: "/assets/icons/finish-flag.svg",
  },
  {
    id: 29,
    name: "cross-red",
    alt: "cross-red",
    src: "/assets/icons/cross-red.svg",
  },
  {
    id: 30,
    name: "blue-person",
    alt: "blue-person",
    src: "/assets/icons/blue-person.svg",
  },
  {
    id: 31,
    name: "clocks",
    alt: "clocks",
    src: "/assets/icons/clocks.svg",
  },
  {
    id: 32,
    name: "envelop",
    alt: "envelop",
    src: "/assets/icons/envelop.svg",
  },
  {
    id: 33,
    name: "spanner",
    alt: "spanner",
    src: "/assets/icons/spanner.svg",
  },
  {
    id: 34,
    name: "document",
    alt: "document",
    src: "/assets/icons/document.svg",
  },
  {
    id: 35,
    name: "money-2",
    alt: "money-2",
    src: "/assets/icons/money-2.svg",
  },
  {
    id: 36,
    name: "arrows",
    alt: "arrows",
    src: "/assets/icons/arrows.svg",
  },
  {
    id: 37,
    name: "flat",
    alt: "flat",
    src: "/assets/icons/flat.svg",
  },
  {
    id: 38,
    name: "house",
    alt: "house",
    src: "/assets/icons/house.svg",
  },
  {
    id: 39,
    name: "planned",
    alt: "planned",
    src: "/assets/icons/planned.svg",
  },
  {
    id: 40,
    name: "success-2",
    alt: "success-2",
    src: "/assets/icons/success-2.svg",
  },
  {
    id: 41,
    name: "inProgress",
    alt: "inProgress",
    src: "/assets/icons/inProgress.svg",
  },
  {
    id: 42,
    name: "notPlanned",
    alt: "notPlanned",
    src: "/assets/icons/notPlanned.svg",
  },
  {
    id: 43,
    name: "cancel",
    alt: "cancel",
    src: "/assets/icons/cancel.svg",
  },
  {
    id: 44,
    name: "document75",
    alt: "document75",
    src: "/assets/icons/document75.svg",
  },
  {
    id: 45,
    name: "contract75",
    alt: "contract75",
    src: "/assets/icons/contract75.svg",
  },
  {
    id: 46,
    name: "gear",
    alt: "gear",
    src: "/assets/icons/gear.svg",
  },
  {
    id: 47,
    name: "gears",
    alt: "gears",
    src: "/assets/icons/gears.svg",
  },
  {
    id: 48,
    name: "flag-russia",
    alt: "flag-russia",
    src: "/assets/icons/flag-russia.svg",
  },
  {
    id: 49,
    name: "flag-spain",
    alt: "flag-spain",
    src: "/assets/icons/flag-spain.svg",
  },
  {
    id: 50,
    name: "contract-hands",
    alt: "contract-hands",
    src: "/assets/icons/contract-hands.svg",
  },
  {
    id: 51,
    name: "check-2",
    alt: "check-2",
    src: "/assets/icons/check-2.svg",
  },
  {
    id: 52,
    name: "broken",
    alt: "broken",
    src: "/assets/icons/broken.svg",
  },
  {
    id: 53,
    name: "ascBlue",
    alt: "ascBlue",
    src: "/assets/icons/asc-blue.svg",
  },
  {
    id: 54,
    name: "descBlue",
    alt: "descBlue",
    src: "/assets/icons/desc-blue.svg",
  },
  {
    id: 55,
    name: "tariff-services",
    alt: "tariff-services",
    src: "/assets/icons/tariff-services.svg",
  },
  {
    id: 56,
    name: "tariff-additional-service",
    alt: "tariff-additional-service",
    src: "/assets/icons/tariff-additional-service.svg",
  },
  {
    id: 57,
    name: "black-blue-hands",
    alt: "black-blue-hands",
    src: "/assets/icons/black-blue-hands.svg",
  },
  {
    id: 58,
    name: "uncompletely",
    alt: "uncompletely",
    src: "/assets/icons/uncompletely.svg",
  },
  {
    id: 59,
    name: "magnifier",
    alt: "magnifier",
    src: "/assets/icons/magnifier.svg",
  },
  {
    id: 60,
    name: "header-mobile-menu",
    alt: "header-mobile-menu",
    src: "/assets/icons/header-mobile-menu.svg",
  },
  {
    id: 61,
    name: "mobile-home",
    alt: "mobile-home",
    src: "/assets/icons/mobile-home.svg",
  },
  {
    id: 62,
    name: "bill",
    alt: "bill",
    src: "/assets/icons/bill.svg",
  },
  {
    id: 63,
    name: "process-of-elimination",
    alt: "process-of-elimination",
    src: "/assets/icons/process-of-elimination.svg",
  },
];

export const iconsByName = {
  default: icons[0],
  person: icons[1],
  logout: icons[2],
  check: icons[3],
  arrow: icons[4],
  plus: icons[5],
  contextMenu: icons[6],
  pencil: icons[7],
  arrowSelector: icons[8],
  crossClose: icons[9],
  selectItem: icons[10],
  filter: icons[11],
  noneSort: icons[12],
  asc: icons[13],
  desc: icons[14],
  noneSortMini: icons[15],
  ascMini: icons[16],
  descMini: icons[17],
  trash: icons[18],
  quotationLeft: icons[19],
  quotationRight: icons[20],
  calendar: icons[21],
  calendarDay: icons[22],
  filterBig: icons[23],
  calendarBlue: icons[24],
  calendarDayBlue: icons[25],
  success: icons[26],
  sandClock: icons[27],
  finishFlag: icons[28],
  crossRed: icons[29],
  bluePerson: icons[30],
  clocks: icons[31],
  envelop: icons[32],
  spanner: icons[33],
  document: icons[34],
  money2: icons[35],
  arrows: icons[36],
  flat: icons[37],
  house: icons[38],
  planned: icons[39],
  success2: icons[40],
  inProgress: icons[41],
  notPlanned: icons[42],
  cancel: icons[43],
  document75: icons[44],
  contract75: icons[45],
  gear: icons[46],
  gears: icons[47],
  flagRussia: icons[48],
  flagSpain: icons[49],
  contractHands: icons[50],
  check2: icons[51],
  broken: icons[52],
  ascBlue: icons[53],
  descBlue: icons[54],
  tariffServices: icons[55],
  tariffAdditionalServices: icons[56],
  blackBlueHands: icons[57],
  uncompletely: icons[58],
  magnifier: icons[59],
  headerMobileMenu: icons[60],
  mobileHome: icons[61],
  bill: icons[62],
  processOfElimination: icons[63],
}


