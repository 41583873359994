import {Component, OnInit} from '@angular/core';
import {SectionComponent} from "../../../../../elements/section/section.component";
import {ClientSubheaderComponent} from "../../../../../elements/client-subheader/client-subheader.component";
import {ClientSubheader} from "../../../../../../model/clientSubheader";
import {iconsByName} from "../../../../../../model/icon";
import {Country} from "../../../../../../model/country";
import {ApiPath} from "../../../../../../model/global";
import {text} from "../../../../../../function/localization";
import {HeaderService} from "../../../../../../services/header.service";
import {MenuService} from "../../../../../../services/menu.service";
import {ApiService} from "../../../../../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Task} from "../../../../../../model/task";
import {LoadingComponent} from "../../../../../elements/loading/loading.component";
import {NgForOf, NgIf} from "@angular/common";
import {formatDate} from "../../../../../../function/formatDate";
import {IconComponent} from "../../../../../elements/icon/icon.component";
import {CheckboxComponent} from "../../../../../elements/checkbox/checkbox.component";
import {TaskServices} from "../../../../../../model/taskServices";
import {CellItemComponent} from "../../../../../elements/cell-item/cell-item.component";
import {Defect} from "../../../../../../model/defect";
import {PhotoComponent} from "../../../../../elements/photo/photo.component";

@Component({
  selector: 'app-client-inspection-page',
  standalone: true,
  imports: [
    SectionComponent,
    ClientSubheaderComponent,
    LoadingComponent,
    NgIf,
    IconComponent,
    CheckboxComponent,
    NgForOf,
    CellItemComponent,
    PhotoComponent
  ],
  templateUrl: './client-inspection-page.component.html',
  styleUrl: './client-inspection-page.component.scss'
})
export class ClientInspectionPageComponent implements OnInit {

  constructor(public headerService: HeaderService,
              protected apiService: ApiService,
              private route: ActivatedRoute,
              private router: Router) {}

  activeTab: number = 0;
  activeDefectTab: number = 0;
  loading = false;
  taskId: null | string = null;
  contractId: null | string = null;
  task: Task | null = null;
  taskServices: TaskServices[] = [];
  taskServicesAdditional: TaskServices[] = [];
  activeDefect: Defect | null = null;

  subheader: ClientSubheader = {
    title: text(['Журнал осмотров', 'Registro de inspecciones']),
    breadcrumbs: [
      {
        name: text(['Главное меню', 'Menú principal']),
        link: "/client",
      },
      {
        name: text(['Журнал осмотров', 'Registro de inspecciones']),
        link: "/client/inspection-log",
      },
    ],
    icon: iconsByName.spanner,
    backLink: true,
    color: "#3abeb1",
  }

  ngOnInit() {
    this.taskId = this.route.snapshot.paramMap.get('number') ?? '';
    this.contractId = this.route.snapshot.paramMap.get('id') ?? '';
    this.getTask();
  }

  getTask(){
    this.loading = true;
    this.apiService.getObj<Task>(ApiPath.ClientTasksById, Number(this.taskId), true).subscribe((data) => {
      this.task = new Task(data);
      this.taskServices = this.task.taskServices.filter(v => !v.isAdditional);
      this.taskServicesAdditional = this.task.taskServices.filter(v => v.isAdditional);
      this.subheader.breadcrumbs.push(
        {
          name: text(['Договор № ', 'Tratado № ']) + this.task?.contract?.number,
          link: "/client/inspection-log/contract/" + (this.task.contract?.id ?? ''),
        },
        {
          name: text(['Осмотр ', 'Inspección ']) + formatDate(this.task.planDate),
          link: "",
        },
      );
      this.loading = false;
    });
  }

  back(){
    this.router.navigate(["/client/inspection-log/contract/" + this.contractId],{relativeTo: this.route}).then(r => r);
  }

  setActiveDefect(defect: Defect) {
    this.activeDefectTab = 0;
    this.activeDefect = defect;
  }

  getIcon(bool: boolean) {
    if (this.task?.status == 'IN_PROGRESS') {
    // if (this.task?.status == 'FINISHED') {
      if (!bool) {
        return iconsByName.processOfElimination;
      } else return iconsByName.check2;
    } else {
      if (!bool) {
        return iconsByName.broken;
      } else return iconsByName.check2;
    }
  }

  getColor(bool: boolean) {
if (this.task?.status == 'IN_PROGRESS') {
    // if (this.task?.status == 'FINISHED') {
      if (!bool) {
        return '#E88124';
      } else return '#4DCA76';
    } else {
      if (!bool) {
        return '#ff0000';
      } else return '#4DCA76';
    }
  }

  getTitle(bool: boolean) {
if (this.task?.status == 'IN_PROGRESS') {
    // if (this.task?.status == 'FINISHED') {
      if (!bool) {
        return text(['В процессе', 'En gestión']);
      } else return text(['Устранено', 'Eliminado']);
    } else {
      if (!bool) {
        return text(['Не устранено', 'No resuelto']);
      } else return text(['Устранено', 'Eliminado']);
    }
  }


  protected readonly formatDate = formatDate;
  protected readonly iconsByName = iconsByName;
  protected readonly text = text;
}
